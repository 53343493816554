<template>
  <div class="dashboard container-fluid">
    <div class="row">
      <div
        class="col-12 col-md-6"
        v-for="(array, index) in arrays"
        :key="index"
      >
        <div class="card mb-4 custom-card border-0">
          <div class="card-header text-white fw-strong">
            CAM {{ index + 1 }}
          </div>
          <div class="card-body p-0">
            <video
              :id="`liveStream-${index}`"
              muted
              playsinline
              class="videoPlayer"
            >
              <source :id="`mp4-${index}`" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flvjs from "flv.js";

export default {
  data() {
    return {
      isLoading: false,
      flvPlayer: [],
      isLoadingStream: false,
      arrays: [
        {
          url: null,
          isOnline: false,
          isInitialized: false,
        },
        {
          url: null,
          isOnline: false,
          isInitialized: false,
        },
        {
          url: null,
          isOnline: false,
          isInitialized: false,
        },
        {
          url: null,
          isOnline: false,
          isInitialized: false,
        },
      ],
    };
  },
  methods: {
    flvjsLogListener(_, str) {
      if (str.includes("onSourceEnded")) {
        const data = this.arrays.map((x) => {
          return {
            ...x,
            isOnline: false,
          };
        });

        this.arrays = data;
      }

      if (str.includes("Received Initialization")) {
        if (this.flvPlayer.length !== 0) {
          const data = this.arrays.map((x) => {
            return {
              ...x,
              isOnline: true,
            };
          });

          this.arrays = data;
          for (let i = 0; i < this.flvPlayer.length; i++) {
            this.flvPlayer[i].play();
          }
        }
      }
    },
  },
  mounted() {
    this.arrays = [
      {
        url: "https://playback.inference.asia/live/suk1.flv",
        isOnline: true,
        isInitialized: false,
      },
      {
        url: "https://playback.inference.asia/live/suk2.flv",
        isOnline: true,
        isInitialized: false,
      },
      {
        url: "https://playback.inference.asia/live/suk3.flv",
        isOnline: true,
        isInitialized: false,
      },
      {
        url: "https://playback.inference.asia/live/suk4.flv",
        isOnline: true,
        isInitialized: false,
      },
    ];
    if (flvjs.isSupported()) {
      for (let i = 0; i < this.arrays.length; i++) {
        let videoElement = document.getElementById(`liveStream-${i}`);
        if (videoElement != undefined && this.arrays[i].url != null) {
          flvjs.LoggingControl.removeLogListener(this.flvjsLogListener);

          flvjs.LoggingControl.addLogListener(this.flvjsLogListener);
          if (this.flvPlayer.length !== 0) {
            if (this.flvPlayer[i] != undefined) {
              this.flvPlayer[i].unload();
              this.flvPlayer[i].detachMediaElement();
              this.flvPlayer[i].destroy();
              this.flvPlayer[i] = null;
            }
          }

          let mp4 = document.getElementById(`mp4-${i}`);

          videoElement.controls = false;

          mp4.src = null;

          let newArrays = [
            ...this.flvPlayer,
            flvjs.createPlayer({
              type: "flv",
              url: this.arrays[i].url,
              hasAudio: false,
              enableStashBuffer: false,
            }),
          ];

          this.flvPlayer = newArrays;

          this.flvPlayer[i].attachMediaElement(videoElement);

          this.flvPlayer[i].load();
        }
      }
    }
  },
};
</script>

<style scoped>
.fw-strong {
  font-weight: bolder;
}

.custom-card {
  background-color: #181f23;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 20%) 0px 0px 5px 1px !important;
}

.custom-padding {
  padding: 6rem 1.5rem 2rem 1.5rem !important;
}

.videoPlayer {
  object-fit: contain;
  aspect-ratio: 16/9;
  width: 100%;
}
</style>